import { useEffect } from "react";
import { toast } from "react-toastify";
import { PageTitleBox } from "../../Others/PageTitleBox";
import ProductCollectionStore from "../../Stores/ProductCollectionStore";
import CustomDocumentTitle from "../../Utils/CustomDocumentTitle";
import { SectionTitle } from "../../Others/SectionTitle";
import UserApiService from "../../../Services/UserApiService";

interface IProps {
    productCollectionStore?: ProductCollectionStore
}

const userApiService = new UserApiService();

export const ProductsArchivePage = (props: IProps) => {

    useEffect(() => {
        return () => {
            userApiService.cancelRequests();
        }
    }, [])
     /*
    const [form, setForm] = useState<IProductsExcelExportRequestDto>({ productCollectionId: '' });
  

    const onChange = (key: string, val: any) => {
        setForm({
            ...form,
            [key]:val,
        })
    }

   const download = () => {
        const isValid = ValidationHelper.validateInputs([]);

        if (isValid) {
           // window.open(`/api/products/export/archive?productCollectionId=${form.productCollectionId}`, '_blank');
            toast.success('Stahování zahájeno');
        } 
    }*/

    return (
        <>
            <CustomDocumentTitle title="Archív">
                <PageTitleBox title="Archív" />

                <div className="card">
                    <div className="card-body">
                        <SectionTitle className="mt-4" title="Archív BETA (funkce v testováni, vývoji)" />
                        <div className="mt-3">
                            <p>Zde můžete stáhnout Excel soubor obsahující všechny vaše smazané produkty včetně základních informací. Neslouží pro následný import.</p>

                            <button
                                className="btn btn-primary"
                                onClick={async () => {
                                    try {
                                        const response = await userApiService.getDeletedProductsExcelUrl();
                                        if (response.data?.url) {
                                            window.location.href = response.data.url;
                                        }
                                    } catch (error) {
                                        toast.error('Soubor zatím neexistuje');
                                    }
                                }}
                            >
                                Stáhnout
                            </button>
                        </div>

                    </div>
                </div>
            </CustomDocumentTitle>
        </>
    );
}