﻿import { useEffect, useRef, useState } from "react"
import SelectBox from "../../UI/Input/SelectBox"
import CurrentUserStore from "../../Stores/CurrentUserStore";
import { inject, observer } from "mobx-react";
import LanguageHelper from "../../../Helpers/LanguageHelper";
import CustomDocumentTitle from "../../Utils/CustomDocumentTitle";
import { PageTitleBox } from "../../Others/PageTitleBox";
import CheckBox from "../../UI/Input/CheckBox";
import { IAppSettingsForm } from "../../../Interfaces/Requests/Settings/IAppSettingsForm";
import UserApiService from "../../../Services/UserApiService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { SectionTitle } from "../../Others/SectionTitle";
import InputNumber from "../../UI/Input/InputNumber";
import InputPrice from "../../UI/Input/InputPrice";
import InputText from "../../UI/Input/InputText";
import InputBankAccount from "../../UI/Input/InputBankAccount";
import { Tabs, Tab } from "react-bootstrap";
import { ContactsForm } from "../../MyBusiness/ContactsForm";
import LocationForm from "../../MyBusiness/LocationForm";
import InputUrl from "../../UI/Input/InputUrl";
import { IContacts } from "../../../Interfaces/IContacts";
import { ILocation } from "../../../Interfaces/ILocation";
import IUser from "../../../Interfaces/IUser";
import { ValidationHelper } from "../../Utils/ValidationHelper";

interface IProps {
    currentUserStore?: CurrentUserStore
}

const userApiService = new UserApiService();

export const AppSettingsPage = inject('currentUserStore')(observer((props: IProps) => {

    useEffect(() => {
        return () => {
            userApiService.cancelRequests();
        }
    }, [])

    const { currentUserStore } = props;
    const user = currentUserStore.user;

    const [form, setForm] = useState<IAppSettingsForm>({
        language: user.language,
        enableWebEmbed: user.enableWebEmbed,
        vatPayer: user.vatPayer,
        vatRate: user.vatRate,
        doNotStoreBusinessData: user.doNotStoreBusinessData,
        defaultCommissionPercentage: user.defaultCommissionPercentage,
        defaultMinCommission: user.defaultMinCommission,
        priceExchangeRateDiffPercentageWarning: user.priceExchangeRateDiffPercentageWarning,
        allowSmartPriceRound: user.allowSmartPriceRound,
        eurToCzkRate: user.eurToCzkRate,
        aiGeneratedWebsiteContentPrompt: user.aiGeneratedWebsiteContentPrompt,
        deliveryPrice: user.deliveryPrice,
        isPhysicalPickupAllowed: user.isPhysicalPickupAllowed,
        isDeliveryAllowed: user.isDeliveryAllowed,
        bankAccountNumber: user.bankAccountNumber,
        bankCode: user.bankCode,
        bankIBAN: user.bankIBAN,
        bankSWIFT: user.bankSWIFT,
        allowOrderBankPayment: user.allowOrderBankPayment,
        deliveryInfo: user.deliveryInfo,
        pickupInfo: user.pickupInfo,
        paymentInfo: user.paymentInfo,
        contacts: user.contacts,
        location: user.location,
        webUrl: user.webUrl,
    });

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const locationFormRef = useRef<LocationForm>(null);
    const webUrlRef = useRef<InputUrl>(null);

    const onSave = () => {

        var locationValid = locationFormRef.current.validate();

        const inputs = [webUrlRef];
        const isValid = ValidationHelper.validateInputs(inputs);

        if (locationValid && isValid) {
            setLoading(true);

            userApiService.saveAppSettings(form)
                .then((data) => {
                    if (data.success) {
                        toast.success("Změny uloženy")
                        navigate('/');
                    }
                })
                .finally(() => {
                    setLoading(false);
                })
        }  
    }

    const onChange = (key: string, value: any) => {
        setForm({
            ...form,
            [key]: value,
        })
    }

    const handleChangeLocation = (value: any, key: keyof ILocation) => {
        setForm({
            ...form,
            location: {
                ...user.location,
                [key]: value,
            }
        })
    }

    const handleChangeContacts = (value: any, key: keyof IContacts) => {
        setForm({
            ...form,
            contacts: {
                ...user.contacts,
                [key]: value,
            }
        })
    }

    const handleChangeUser = (value: any, key: keyof IUser) => {
        setForm({
            ...form,
            [key]: value,
        })
    }

    return (
        <>
            <CustomDocumentTitle title={`Nastavení aplikace`}>
                <PageTitleBox title="Nastavení aplikace" />

                <div className="card">
                    <div className="card-body">
                        <Tabs
                            id="uncontrolled-tab-example"
                            className="nav nav-justified mt-4"
                            defaultActiveKey='general'
                        >
                            <Tab
                                eventKey="general"
                                title="Základní"
                                tabClassName="nav-item"
                            >
                                <div className="mt-3">
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <SelectBox
                                                label="Výchozí jazyk pro zadávání / export na web"
                                                onChange={(val) => onChange('language', val)}
                                                value={form.language}
                                                options={LanguageHelper.getOptions()}
                                                defaultValue="Vyberte"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <CheckBox
                                        checked={form.enableWebEmbed}
                                        label="Povolit webové widgety (vložení nabídky, google hodnocení apod)"
                                        onChange={(val) => onChange('enableWebEmbed', val)}
                                    />

                                    <label className="section-title pb-3">Webové stránky</label>

                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <InputUrl
                                                ref={webUrlRef}
                                                label="Url webu"
                                                onChange={(val) => handleChangeUser(val, 'webUrl')}
                                                value={form.webUrl}
                                                placeholder="Zadejte url vašeho webu"
                                            />
                                        </div>

                                        <div className="col-12 col-md-4">

                                        </div>
                                    </div>

                                  
                                </div>
                            </Tab>

                            <Tab
                                eventKey="ai"
                                title="Umělá inteligence"
                                tabClassName="nav-item"
                            >

                                <div className="mt-3">
                                    <p>Můžete si přizpůsobit prompty, které mají vliv na generování obsahu.</p>

                                    <div className="alert alert-info">
                                        <h6 className="alert-heading">Co je prompt?</h6>

                                        <hr />
                                        <p>
                                            Prompt je textové zadání, které poskytujete našemu systému,
                                            a podle něj generujeme odpovědi nebo výstupy.
                                            Pokud výsledky neodpovídají vašim očekáváním,
                                            <b>můžete prompt upravit tak, aby byl přesnější a lépe vyhovoval vašim požadavkům.</b>
                                        </p>

                                        <p>Můžete ovlivnit styl, tón a další parametry výstupu.</p>
                                    </div>

                                    <InputText
                                        label="Doplnění promptu - Generování HTML popisu pro web"
                                        value={form.aiGeneratedWebsiteContentPrompt || "Nepiš jej z pohledu první osoby. Bez marketingových frází. Neuváděj kontaktní údaje. Nepiš nic o dostupnosti produktu."}
                                        onChange={(val) => onChange('aiGeneratedWebsiteContentPrompt', val)}
                                    />
                                    <small>Např: "Nepiš jej z pohledu první osoby. Bez marketingových frází. Neuváděj kontaktní údaje. Nepiš nic o dostupnosti produktu."</small>
                                </div>
                            </Tab>

                            <Tab
                                eventKey="web"
                                title="Prodej na webu"
                                tabClassName="nav-item"
                            >
                                <div className="mt-3">

                                    <CheckBox
                                        checked={form.allowOrderBankPayment}
                                        label="Povolit platbu převodem (pouze CZK)"
                                        onChange={(val) => onChange('allowOrderBankPayment', val)}
                                    />


                                    <CheckBox
                                        checked={form.isPhysicalPickupAllowed}
                                        label="Povolit osobní odběr"
                                        onChange={(val) => onChange('isPhysicalPickupAllowed', val)}
                                    />

                                    <CheckBox
                                        checked={form.isDeliveryAllowed}
                                        label="Povolit doručení"
                                        onChange={(val) => onChange('isDeliveryAllowed', val)}
                                    />

                                    <div className="row mt-2">
                                        {form.isDeliveryAllowed &&
                                            <>
                                                <div className="col-12 col-md-6">
                                                    <InputPrice
                                                        label="Cena za doručení"
                                                        value={form.deliveryPrice}
                                                        onChange={(val) => onChange('deliveryPrice', val)}
                                                        currency={user.accountCurrency}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <InputText
                                                        label="Informace o doručení"
                                                        value={form.deliveryInfo}
                                                        onChange={(val) => onChange('deliveryInfo', val)}
                                                    />
                                                </div>
                                            </>
                                        }

                                        {form.isPhysicalPickupAllowed &&
                                            <>
                                                <div className="col-12 col-md-6">
                                                    <InputText
                                                        label="Informace o vyzvednutí"
                                                        value={form.pickupInfo}
                                                        onChange={(val) => onChange('pickupInfo', val)}
                                                    />
                                                </div>
                                            </>
                                        }

                                        <div className="col-12 col-md-6">
                                            <InputText
                                                label="Informace o platbě"
                                                value={form.paymentInfo}
                                                onChange={(val) => onChange('paymentInfo', val)}
                                            />
                                        </div>
                                    </div>

                                    <SectionTitle className="mt-4" title="Bankovní údaje" />
                                    <hr />
                                    <div className="row">

                                        <div className="col-12 col-md-3">
                                            <InputBankAccount
                                                label="Bankovní účet"
                                                value={form.bankAccountNumber}
                                                onChange={(val) => onChange('bankAccountNumber', val)}
                                                allowOnlyDigits
                                            />
                                        </div>

                                        <div className="col-12 col-md-2">
                                            <InputText
                                                label="Kód banky"
                                                value={form.bankCode}
                                                onChange={(val) => onChange('bankCode', val)}
                                                allowOnlyDigits
                                            />
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <InputText
                                                label="IBAN"
                                                value={form.bankIBAN}
                                                onChange={(val) => onChange('bankIBAN', val)}
                                            />
                                        </div>

                                        <div className="col-12 col-md-3">
                                            <InputText
                                                label="SWIFT"
                                                value={form.bankSWIFT}
                                                onChange={(val) => onChange('bankSWIFT', val)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab>

                            <Tab
                                eventKey="store"
                                title="Obchod, ceny, měnová konverze"
                                tabClassName="nav-item"
                            >
                                <div className="mt-3">
                                    <CheckBox
                                        checked={form.doNotStoreBusinessData}
                                        label="Neukládat obchodní údaje"
                                        onChange={(val) => onChange('doNotStoreBusinessData', val)}
                                    />

                                    <div className="row mt-2">
                                        <div className="col-12 col-md-6">
                                            <SectionTitle className="mt-4" title="Nastavení cen a DPH" />

                                            <hr />

                                            <p>Toto nastavení ovlivňuje to, jak pracujete s cenami.</p>

                                            <CheckBox
                                                checked={form.vatPayer}
                                                label="Jsem plátce DPH"
                                                onChange={(val) => onChange('vatPayer', val)}
                                            />

                                            {form.vatPayer &&
                                                <div className="mt-3">
                                                    <InputNumber
                                                        label="Výchozí sazba DPH"
                                                        value={form.vatRate}
                                                        onChange={(val) => onChange('vatRate', val)}
                                                        suffix="%"
                                                    />
                                                </div>
                                            }
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <SectionTitle className="mt-4" title="Cizí měny" />

                                            <hr />

                                            <InputNumber
                                                label="Tolerovaný rozdíl mezi cenou v jednotlivých měnách"
                                                value={form.priceExchangeRateDiffPercentageWarning}
                                                suffix="%"
                                                onChange={(val) => onChange('priceExchangeRateDiffPercentageWarning', val)}
                                                allowDouble={true}
                                                maxValue={100}
                                                minValue={0.5}
                                            />
                                            <small>Při překročení uvídíte v úpravě inzerátu upozornění na tento rozdíl.</small>

                                            {form.priceExchangeRateDiffPercentageWarning > 0 &&
                                                <div className="mt-3">
                                                    <CheckBox
                                                        checked={form.allowSmartPriceRound}
                                                        onChange={(checked) => onChange('allowSmartPriceRound', checked)}
                                                        label="Smart zaokrouhlování na tisíce a stovky v rámci tolerance"
                                                    />
                                                    <p>
                                                        <small>Uplatní se jen pro přepočet do cizích měn.</small>
                                                        <br />
                                                        <small>např. <b>13 666 Kč ={">"} 13 700 Kč</b> nebo <b>1 256 611 Kč ={">"} 1 257 000 Kč</b></small>
                                                    </p>
                                                </div>}

                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <InputNumber
                                                        label={"Pevný kurz"}
                                                        value={form.eurToCzkRate}
                                                        onChange={(val) => onChange('eurToCzkRate', val)}
                                                        allowDouble={true}
                                                        suffix="EUR/CZK"
                                                    />
                                                    <small>Ponechte prázdné pro použivání automatického kurzu.</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>

                        
                                <Tab
                                    eventKey="contacts"
                                    title="Kontakty"
                                    tabClassName="nav-item"
                                >
                                    <div className="mt-3">
                                    <ContactsForm
                                        contacts={form.contacts}
                                        onChange={handleChangeContacts}
                                    />
                                    </div>
                            </Tab>

                            <Tab
                                eventKey="place"
                                title="Provozovna"
                                tabClassName="nav-item"
                            >
                                <div className="mt-3">

                                    <label className="section-title pb-3">Údaje o provozovně</label>

                                    <div className="pb-1">
                                        <LocationForm
                                            ref={locationFormRef}
                                            location={form.location}
                                            onChange={handleChangeLocation}
                                        />
                                    </div>
                                </div>
                            </Tab>

                        </Tabs>

                        {/*  <SectionTitle className="mt-3" title="Komisní prodej" />
                        <hr />

                        <div className="row my-3">
                            <div className="col-12 col-md-3">
                                <InputNumber
                                    label="Výchozí provize"
                                    value={form.defaultCommissionPercentage}
                                    suffix="%"
                                    onChange={(val) => onChange('defaultCommissionPercentage', val)}
                                    allowDouble={true}
                                    maxValue={100}
                                />
                            </div>

                            <div className="col-12 col-md-3">
                                <InputPrice
                                    label="Min. provize"
                                    value={form.defaultMinCommission}
                                    currency={user.accountCurrency}
                                    onChange={(val) => onChange('defaultMinCommission', val)}
                                />
                            </div>
                        </div>
                        */}

                        <hr />
                        <div className="text-center mt-5 mb-4">
                            <button className="btn btn-primary btn-lg" onClick={onSave}>
                                Uložit nastavení
                            </button>
                        </div>
                    </div>
                </div>
            </CustomDocumentTitle>
        </>
    )
}))
