import * as React from 'react';
import './AxiosSettings';
import { createRoot } from 'react-dom/client';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { ErrorBoundary } from './components/Utils/ErrorBoundary';
import { FaqPage } from './components/Pages/Faq/FaqPage';
import { FaqBazosPage } from './components/Pages/Faq/FaqBazosPage';
import { FaqSbazarPage } from './components/Pages/Faq/FaqSbazarPage';
import { FaqWebsitePage } from './components/Pages/Faq/FaqWebsitePage';
import { AccountTransactionsPage } from './components/Pages/Account/AccountTransactionsPage';
import { WebsiteSettingsPage } from './components/Pages/Websites/WebsiteSettingsPage';
import SettingsPage from './components/Pages/Settings/SettingsPage';
import { AppSettingsPage } from './components/Pages/Settings/AppSettingsPage';
import { DashboardPage } from './components/Pages/DashboardPage';
import { PricingPage } from './components/Pages/Pricing/PricingPage';
import { PricingStandardPage } from './components/Pages/Pricing/PricingStandardPage';
import { PricingAutoMotoPage } from './components/Pages/Pricing/PricingAutoMotoPage';
import { PricingWebsitesPage } from './components/Pages/Pricing/PricingWebsitesPage';
import { WebmailPricingPage } from './components/Pages/Pricing/WebmailPricingPage';
import { DomainsPricingPage } from './components/Pages/Pricing/DomainsPricingPage';
import { ProductsErrorsPage } from './components/Pages/Products/ProductsErrorsPage';
import { IpAddressesPage } from './components/Pages/IpAddresses/IpAddressesPage';
import AutoImporterSautoPage from './components/Pages/Autoimporters/AutoImporterSautoPage';
import AutoImporterTipcarsPage from './components/Pages/Autoimporters/AutoImporterTipcarsPage';
import FacebookShopsPage from './components/Pages/Integrations/FacebookShops/FacebookShopsPage';
import WebsitesPage from './components/Pages/Websites/WebsitesPage';
import { WebmailsPage } from './components/Pages/Webmails/WebmailsPage';
import { EmailRemarketingPage } from './components/Pages/Remarketing/EmailRemarketingPage';
import { ProformaInvoicesListPage } from './components/Pages/ProformaInvoices/ProformaInvoicesListPage';
import TipMotoExportPage from './components/Pages/Integrations/TipMoto/TipMotoExportPage';
import { AutobazarEuExportPage } from './components/Pages/Integrations/AutobazarEu/AutobazarEuExportPage';
import { ExportFeedPage } from './components/Pages/Integrations/Export/ExportFeedPage';
import { WebEmbedPage } from './components/Pages/Integrations/WebEmbed/WebEmbedPage';
import { WebEmbedReviewsPage } from './components/Pages/Integrations/WebEmbed/WebEmbedReviewsPage';
import { IntegrationsPage } from './components/Pages/Integrations/IntegrationsPage';
import FacebookAccountsPage from './components/Pages/Integrations/FacebookAccounts/FacebookAccountsPage';
import { ContactUsPage } from './components/Pages/ContactUsPage';
import { NotFound } from './components/Pages/NotFound';
import App from './App';
import { EditCollectionPage } from './components/Pages/ProductCollections/EditCollectionPage';
import { CollectionsPage } from './components/Pages/ProductCollections/CollectionsPage';
import { OverviewCollectionPage } from './components/Pages/ProductCollections/OverviewCollectionPage';
import { ShopOverviewPage } from './components/Pages/Shop/ShopOverviewPage';
import { ShopPage } from './components/Pages/Shop/ShopPage';
import { SbazarAccountsPage } from './components/Pages/Integrations/Sbazar/SbazarAccountsPage';
import { CreateCollectionPage } from './components/Pages/ProductCollections/CreateCollectionPage';
import { ShopTrashPage } from './components/Pages/Shop/ShopTrashPage';
import AutoImporterAutobazarEuPage from './components/Pages/Autoimporters/AutoImporterAutobazarEuPage';
import { GoogleMerchantPage } from './components/Pages/Integrations/GoogleMerchant/GoogleMerchantPage';
import AutoImporterGoogleShoppingPage from './components/Pages/Autoimporters/AutoImporterGoogleShoppingPage';
import { AutoImporterSbazarPage } from './components/Pages/Autoimporters/AutoImporterSbazarPage';
import { AutoImporterSbazarDetailPage } from './components/Pages/Autoimporters/AutoImporterSbazarDetailPage';
import { AutoImporterCsvPage } from './components/Pages/Autoimporters/AutoImporterCsvPage';
import { AutoImporterCsvDetailPage } from './components/Pages/Autoimporters/AutoImporterCsvDetailPage';
import { DefaultLayout } from './components/Layouts/DefaultLayout';
import { ProductEditPage } from './components/Pages/Products/ProductEditPage';
import { CreateProductPage } from './components/Pages/Products/CreateProductPage';
import { ProductType } from './Enums/ProductType';
import { BazosPage } from './components/Pages/Integrations/Bazos/BazosPage';
import { ProductsPage } from './components/Pages/Products/ProductsPage';
import { SautoExportPage } from './components/Pages/Integrations/Sauto/SautoExportPage';
import { GoogleMapsPage } from './components/Pages/Integrations/GoogleMaps/GoogleMapsPage';
import { ExportExcelPage } from './components/Pages/Integrations/Export/ExportExcelPage';
import { ProductsArchivePage } from './components/Pages/Products/ProductsArchivePage';
import { AutoImporterSbazarPublicPage } from './components/Pages/Autoimporters/AutoImporterSbazarPublicPage';
import { AutoImporterSbazarPublicDetailPage } from './components/Pages/Autoimporters/AutoImporterSbazarPublicDetailPage';

const container = document.getElementById('root');
const root = createRoot(container!);

const router = createBrowserRouter(createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />} element={<App />}>
        {/* sprava autobazaru }

        <Route element={
            <DefaultLayout>
                <VehicleManagerPage />
            </DefaultLayout>} path="/vehiclemanager/list" />

        {/* konec sprava autobazaru */}

        <Route element={
            <DefaultLayout>
                <FaqPage />
            </DefaultLayout>} path="/faq" />

        <Route element={
            <DefaultLayout>
                <FaqBazosPage />
            </DefaultLayout>} path="/faq/bazos" />

        <Route element={
            <DefaultLayout>
                <FaqSbazarPage />
            </DefaultLayout>} path="/faq/sbazar" />

        <Route element={
            <DefaultLayout>
                <FaqWebsitePage />
            </DefaultLayout>} path="/faq/prodejni-weby" />

        <Route element={
            <DefaultLayout>
                <ProductsPage />
            </DefaultLayout>} path="/inzeraty" />

        <Route element={
            <DefaultLayout>
                <CollectionsPage />
            </DefaultLayout>} path="/nahravac/kolekce" />

        <Route element={
            <DefaultLayout>
                <CreateCollectionPage />
            </DefaultLayout>} path="/nahravac/kolekce/pridat" />

        <Route element={
            <DefaultLayout>
                <EditCollectionPage />
            </DefaultLayout>} path="/nahravac/kolekce/:id/upravit" />

        <Route path="/nahravac/kolekce/:id/prehled" element={
            <DefaultLayout>
                <OverviewCollectionPage />
            </DefaultLayout>
        } />

        <Route path="/ucet/transakce" element={
            <DefaultLayout>
                <AccountTransactionsPage />
            </DefaultLayout>
        } />

        <Route element={
            <DefaultLayout>
                <WebsiteSettingsPage />
            </DefaultLayout>} path="/webove-stranky/:id/nastaveni" />

        <Route element={
            <DefaultLayout>
                <SettingsPage />
            </DefaultLayout>} path="/nastaveni" />

        <Route element={
            <DefaultLayout>
                <SettingsPage />
            </DefaultLayout>} path="/nastaveni/prodejce" />

        <Route element={
            <DefaultLayout>
                <AppSettingsPage />
            </DefaultLayout>} path="/nastaveni/aplikace" />

        <Route element={
            <DefaultLayout>
                <DashboardPage />
            </DefaultLayout>} path="/" />

        <Route element={
            <DefaultLayout>
                <PricingPage />
            </DefaultLayout>} path="/cenik" />

        <Route element={
            <DefaultLayout>
                <PricingStandardPage />
            </DefaultLayout>} path="/cenik/standard" />

        <Route element={
            <DefaultLayout>
                <PricingAutoMotoPage />
            </DefaultLayout>} path="/cenik/automoto" />

        <Route element={
            <DefaultLayout>
                <PricingWebsitesPage />
            </DefaultLayout>} path="/cenik/weby" />

        <Route element={
            <DefaultLayout>
                <WebmailPricingPage />
            </DefaultLayout>} path="/cenik/webmail" />

        <Route element={
            <DefaultLayout>
                <DomainsPricingPage />
            </DefaultLayout>} path="/cenik/domeny" />

        <Route element={
            <DefaultLayout>
                <ProductsErrorsPage />
            </DefaultLayout>} path="/inzeraty/chybove" />

        <Route element={
            <DefaultLayout>
                <IpAddressesPage />
            </DefaultLayout>} path="/ips" />

        <Route element={
            <DefaultLayout>
                <DashboardPage />
            </DefaultLayout>} path="/nastenka" />

        <Route element={
            <DefaultLayout>
                <DashboardPage />
            </DefaultLayout>} path="/nastenka" />

        <Route element={
            <DefaultLayout>
                <ShopOverviewPage />
            </DefaultLayout>} path="/obchod/prehled" />

        <Route element={
            <DefaultLayout>
                <ShopPage />
            </DefaultLayout>} path="/obchod" />

        <Route element={
            <DefaultLayout>
                <ShopTrashPage />
            </DefaultLayout>} path="/obchod/kos" />

        <Route element={
            <DefaultLayout>
                <AutoImporterSautoPage />
            </DefaultLayout>} path="/autoimporter/sauto" />

        <Route element={
            <DefaultLayout>
                <AutoImporterTipcarsPage />
            </DefaultLayout>} path="/autoimporter/tipcars" />

        <Route element={
            <DefaultLayout>
                <AutoImporterAutobazarEuPage />
            </DefaultLayout>} path="/autoimporter/autobazareu" />

        <Route element={
            <DefaultLayout>
                <AutoImporterGoogleShoppingPage />
            </DefaultLayout>} path="/autoimporter/google-shopping" />

        <Route element={
            <DefaultLayout>
                <AutoImporterSbazarPage />
            </DefaultLayout>} path="/autoimporter/sbazar" />

        <Route element={
            <DefaultLayout>
                <AutoImporterSbazarDetailPage />
            </DefaultLayout>} path="/autoimporter/sbazar/:id" />

        <Route element={
            <DefaultLayout>
                <AutoImporterSbazarPublicPage />
            </DefaultLayout>} path="/autoimporter/sbazar-public" />

        <Route element={
            <DefaultLayout>
                <AutoImporterSbazarPublicDetailPage />
            </DefaultLayout>} path="/autoimporter/sbazar-public/:id" />

        <Route element={
            <DefaultLayout>
                <AutoImporterCsvPage />
            </DefaultLayout>} path="/autoimporter/csv" />

        <Route element={
            <DefaultLayout>
                <AutoImporterCsvDetailPage />
            </DefaultLayout>} path="/autoimporter/csv/:id" />

        <Route element={
            <DefaultLayout>
                <FacebookShopsPage />
            </DefaultLayout>} path="/facebook" />

        <Route element={
            <DefaultLayout>
                <WebsitesPage />
            </DefaultLayout>} path="/webove-stranky" />

        <Route element={
            <DefaultLayout>
                <WebmailsPage />
            </DefaultLayout>} path="/webmail" />

        <Route element={
            <DefaultLayout>
                <EmailRemarketingPage />
            </DefaultLayout>} path="/email-remarketing" />

        <Route element={
            <DefaultLayout>
                <ProformaInvoicesListPage />
            </DefaultLayout>} path="/fakturace" />

        <Route element={
            <DefaultLayout>
                <BazosPage />
            </DefaultLayout>} path="/bazos" />

        <Route element={
            <DefaultLayout>
                <TipMotoExportPage />
            </DefaultLayout>} path="/tipmoto/export" />

        <Route element={
            <DefaultLayout>
                <AutobazarEuExportPage />
            </DefaultLayout>} path="/autobazareu/export" />

        <Route element={
            <DefaultLayout>
                <SautoExportPage />
            </DefaultLayout>} path="/sauto/export" />     

        <Route element={
            <DefaultLayout>
                <GoogleMapsPage />
            </DefaultLayout>} path="/google-maps" />   

        <Route element={
            <ExportFeedPage />
        } path="/export/feed" />

        <Route element={
            <DefaultLayout>
                <WebEmbedPage />
            </DefaultLayout>} path="/export/web" />

        <Route element={
            <DefaultLayout>
                <WebEmbedReviewsPage />
            </DefaultLayout>} path="/export/web/reviews" />

        <Route element={
            <DefaultLayout>
                <IntegrationsPage />
            </DefaultLayout>} path="/integrations" />

        <Route element={
            <DefaultLayout>
                <FacebookAccountsPage />
            </DefaultLayout>} path="/facebook/accounts" />

        <Route element={
            <DefaultLayout>
                <SbazarAccountsPage />
            </DefaultLayout>} path="/sbazar/accounts" />

        <Route element={
            <DefaultLayout>
                <GoogleMerchantPage />
            </DefaultLayout>} path="/google-merchant" />

        <Route element={
            <DefaultLayout>
                <ExportExcelPage />
            </DefaultLayout>} path="/export/excel" />

        <Route element={
            <DefaultLayout>
                <ProductsArchivePage />
            </DefaultLayout>} path="/archive" />

        <Route element={
            <DefaultLayout>
                <ContactUsPage />
            </DefaultLayout>} path="/kontakt" />

        <Route element={
            <DefaultLayout>
                <CreateProductPage type={ProductType.Car} />
            </DefaultLayout>} path="/kolekce/:collectionId/pridat-inzerat/automotostroje" />

        <Route element={
            <DefaultLayout>
                <CreateProductPage type={ProductType.Others} />
            </DefaultLayout>} path="/kolekce/:collectionId/pridat-inzerat/ostatni" />


        <Route element={
            <DefaultLayout>
                <ProductEditPage />
            </DefaultLayout>} path="/inzeraty/:id/upravit" />

        <Route element={
            <DefaultLayout>
                <NotFound />
            </DefaultLayout>} path="*" />
    </Route>
))

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);