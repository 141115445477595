import { Link, useNavigate, useParams } from "react-router-dom"
import { LeavePageWarningPrompt } from "../../Others/LeavePageWarningPrompt"
import ProductCollectionStore from "../../Stores/ProductCollectionStore"
import { inject, observer } from "mobx-react"
import { SectionTitle } from "../../Others/SectionTitle"
import { useEffect, useRef, useState } from "react"
import IProduct from "../../../Interfaces/IProduct"
import CurrentUserStore from "../../Stores/CurrentUserStore"
import WebsiteStore from "../../Stores/WebsiteStore"
import { concat, filter, orderBy, union } from "lodash"
import GlobalSettingsStore from "../../Stores/GlobalSettingsStore"
import ListsStore from "../../Stores/ListsStore"
import { ListType } from "../../../Enums/ListType"
import { IValidationFormRef } from "../../../Interfaces/IValidationFormRef"
import { ValidationHelper } from "../../Utils/ValidationHelper"
import ProductImageStore from "../../Stores/ProductImageStore"
import { SelectMarketingChannelsCard } from "../../Products/OnePageCreateEdit/SelectMarketingChannelsCard"
import { BasicInfoFormCard } from "../../Products/OnePageCreateEdit/BasicInfoFormCard"
import { PricesFormCard } from "../../Products/OnePageCreateEdit/PricesFormCard"
import { BusinessInfoFormCard } from "../../Products/OnePageCreateEdit/BusinessInfoFormCard"
import ProductStore from "../../Stores/ProductStore"
import ProductApiService from "../../../Services/ProductApiService"
import LoadingScreen from "../../Screens/LoadingScreen"
import { NotFound } from "../NotFound"
import { ProductHelper } from "../../../Helpers/ProductHelper"
import { Alert } from "react-bootstrap"
import FacebookCatalogsApiService from "../../../Services/FacebookCatalogsApiService"
import IFormError from "../../../Interfaces/IFormError"
import { FormErrorSummary } from "../../Others/FormErrorSummary"
import { AluminiumWheelsFormCard } from "../../Products/OnePageCreateEdit/AluminiumWheelsFormCard"
import { ProductSubcategory } from "../../../Enums/ProductSubcategory"
import { VehicleType } from "../../../Enums/VehicleType"
import { ProductType } from "../../../Enums/ProductType"
import { TiresFormCard } from "../../Products/OnePageCreateEdit/TiresFormCard"
import IInputField from "../../../Interfaces/IInputField"
import { CarsFormCard } from "../../Products/OnePageCreateEdit/CarsFormCard"
import { MotoFormCard } from "../../Products/OnePageCreateEdit/MotoFormCard"
import FacebookApiService from "../../../Services/FacebookApiService"
import { IFacebookCategory } from "../../../Interfaces/IFacebookCategory"
import GoogleApiService from "../../../Services/GoogleApiService"
import { IGoogleCategory } from "../../../Interfaces/IGoogleCategory"
import { toast } from "react-toastify"
import { OnlineServer } from "../../../Enums/OnlineServer"
import { CategoriesFormCard } from "../../Products/OnePageCreateEdit/CategoriesForm"
import { ServersFormCard } from "../../Products/OnePageCreateEdit/ServersForm"
import { IAiProductState } from "../../Products/OnePageCreateEdit/AiAlert"
import { uploadImages } from "../../Products/Images/uploadImages"
import Dropzone from "react-dropzone"
import { ImagesSortableList } from "../../Products/Images/ImagesSortableList"
import { IField } from "../../../Interfaces/IField"
import Swal from "sweetalert2"
import { WaitScreen } from "../../Screens/WaitScreen"
import { CarsEquipmentFormCard } from "../../Products/OnePageCreateEdit/CarsEquipmentFormCard"

interface IProps {
    productCollectionStore?: ProductCollectionStore,
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
    globalSettingsStore?: GlobalSettingsStore,
    listsStore?: ListsStore,
    productImageStore?: ProductImageStore,
    productStore?: ProductStore,
    type: ProductType,
}

const productApiService = new ProductApiService();
const facebookCatalogsApiService = new FacebookCatalogsApiService();
const facebookApiService = new FacebookApiService();
const googleApiService = new GoogleApiService();

export const CreateProductPage = inject(
    'productCollectionStore',
    'currentUserStore',
    'websiteStore',
    'globalSettingsStore',
    'listsStore',
    'productImageStore',
    'productStore')(observer((props: IProps) => {

        const {
            productCollectionStore,
            currentUserStore,
            globalSettingsStore,
            listsStore,
            productImageStore,
        } = props;

        const onChange = (key: string, value: any, p?: IProduct, ref?: React.MutableRefObject<IValidationFormRef>): IProduct => {
            var productM = ProductHelper.onChangeAutoProcess(p ?? product, key, value);
            setProduct(productM)

            if (key === 'section' && product.section) {
                Swal.fire({
                    icon: 'question',
                    title: "Chcete přepsat zařazení inzerátu?",
                    text: 'Zařazení inzerátu resetujeme a pokusíme se jej znovu doplnit dle nové sekce',
                    confirmButtonText: "Ano, přepsat",
                    cancelButtonText: "Nechat stávající",
                    showCancelButton: true,
                    allowOutsideClick: true,
                }).then(async response => {
                    if (response.value) {
                        productM.bazosCzSection = null;
                        productM.bazosCzCategory = null;
                        productM.bazosSkSection = null;
                        productM.bazosSkCategory = null;
                        productM.sbazarCategoryId = null;
                        productM.googleProductCategoryId = null;
                        productM.facebookCatalogId = null;
                    }
                    setProduct(productM)
                    getMissingFields(false, productM);
                });
            }
            else if (key === 'vehicleType' || key === 'subcategory') {
                productApiService.getRequiredFields(productM)
                    .then(data => {
                        setRequiredFields(data.others.get("requiredFields"));
                    })
            }
            else if (key === 'sbazarCategoryId' &&
                !product.sbazarCategoryId &&
                productM.sbazarCategoryId) {
                getMissingFields(false, productM);
            }
            return productM;
        }

        const onBatchUpdate = (values: { [key: string]: any }) => {
            setProduct(currentProduct => {
                let updatedProduct = { ...currentProduct };
                Object.entries(values).forEach(([key, value]) => {
                    (updatedProduct as any)[key] = value;
                });
                return updatedProduct;
            });
        };

        const getMissingFields = (ai: boolean, p: IProduct) => {
            productApiService.getMissingFields(p, ai)
                .then(data => {
                    var fields = data.others.get("fields") as IField;

                    const count = Object.entries(fields).length;
                    setProduct(currentProduct => {
                        var p = currentProduct;
                        Object.entries(fields).forEach(([key, value]) => {
                            p = ProductHelper.onChangeAutoProcess(p, key, value);
                        });

                        var equipments = data.others.get("equipments") as number[];
                        if (equipments) {
                            p.equipments = union(equipments, p.equipments);
                        }
                        return p;
                    });
                });
        }

        const onChangeMarketingChannels = (key: string, value: any) => {
            var productModified = onChange(key, value);

            productApiService.getRequiredFields(productModified)
                .then(data => {
                    setRequiredFields(data.others.get("requiredFields"));
                })
        }

        const pricesFormRef = useRef<IValidationFormRef>(null);
        const basicFormRef = useRef<IValidationFormRef>(null);
        const businessInfoFormRef = useRef<IValidationFormRef>(null);
        const tiresFormRef = useRef<IValidationFormRef>();
        const aluminiumWheelsFormRef = useRef<IValidationFormRef>();
        const carsFormRef = useRef<IValidationFormRef>();
        const motoFormRef = useRef<IValidationFormRef>();
        const categoriesFormRef = useRef<IValidationFormRef>(null);
        const marketingChannelsRef = useRef<IValidationFormRef>(null);
        const serversFormRef = useRef<IValidationFormRef>(null);
        const subcategories = listsStore.getOptionsBy(ListType.Subcategories);

        const navigate = useNavigate();

        const [loading, setLoading] = useState(true);
        const { collectionId } = useParams();

        const collection = productCollectionStore.getById(collectionId);
        const user = currentUserStore.user;
        const globalSettings = globalSettingsStore.settings;

        const getProduct = (): IProduct => {

            var p: IProduct = {
                name: '',
                bazosCZ: false,
                bazosSK: false,
                dateCreated: new Date(),
                description: '',
                enabled: true,
                id: '',
                priceCZK: null,
                priceEUR: null,
                userId: '',
                productCollectionId: collectionId,

                section: null,
                type: props.type,
                delete: false,

                sbazarPriceByAgreement: false,
                collectionMigrationPending: false,
                vehicleVin: '',

                bazosCzName: '',
                bazosCzPriceOption: 1,
                bazosCzSection: null,
                bazosCzCategory: null,
                bazosCzUseDescription: false,
                bazosCzDescription: '',

                bazosSkName: '',
                bazosSkPriceOption: 1,
                bazosSkSection: null,
                bazosSkCategory: null,
                bazosSkUseDescription: false,
                bazosSkDescription: '',

                sbazar: false,
                sbazarName: '',
                sbazarUseDescription: false,
                sbazarCategoryId: null,
                sbazarDescription: '',
                externalProductUrl: '',
                brand: '',

                facebookName: '',
                facebookDescription: '',
                websiteName: '',
                websiteDescription: '',
                websiteId: null,
                websiteCustomContent: false,
                vatRate: user.vatRate,
                dateOfPurchase: new Date(),
                autobazarEu: false,
                vatDeduction: collection.defaultVatDeduction,
                condition: collection.defaultCondition,

                kleinanzeigen: false,
                kleinanzeigenName: '',
                kleinanzeigenDescription: '',
                kleinanzeigenCustomContent: false,
            }

            if (p.sbazarAccountId &&
                user.uploaderServers.includes(OnlineServer.Sbazar) &&
                (collection.sbazarServerAccountId || collection.sbazar)) {
                p.sbazar = true;
            }

            p.googleMerchant = collection.googleMerchant && user.uploaderServers.includes(OnlineServer.GoogleMerchant);

            if (collection.unlimited && collection.sbazarServerAccountId) {
                p.sbazarAccountId = collection.sbazarServerAccountId;
            }

            if (p.type === ProductType.Car) {
                p.tipMoto = collection.tipMoto && user.uploaderServers.includes(OnlineServer.TipMoto);
                p.autobazarEu = collection.autobazarEu && user.uploaderServers.includes(OnlineServer.AutobazarEu);
                p.vehicleType = collection.defaultVehicleType;
                p.vehicleCondition = collection.defaultVehicleCondition;
            }
            else {
                p.section = collection.defaultSection;
            }

            if (collection.facebookCatalogId && user.uploaderServers.includes(OnlineServer.FacebookShop)) {
                p.facebookCatalogId = collection.facebookCatalogId;
                p.facebookCatalogEnabled = true;
            }

            if (collection.bazosCZ && user.uploaderServers.includes(OnlineServer.BazosCz)) {
                p.bazosCZ = true;
            }

            if (collection.bazosSK && user.uploaderServers.includes(OnlineServer.BazosSk)) {
                p.bazosSK = true;
            }

            if (collection.websiteId) {
                p.websiteId = collection.websiteId;
            }
            return p;
        }

        const [formErrors, setFormErrors] = useState<IFormError[]>([]);
        const [requiredFields, setRequiredFields] = useState<string[]>([]);
        const [fbCategories, setFbCategories] = useState<IFacebookCategory[]>([]);
        const [googleCategories, setGoogleCategories] = useState<IGoogleCategory[]>([]);
        const [created, setCreated] = useState<boolean>(false);
        const [isFormValid, setIsFormValid] = useState<boolean>(false);
        const [blockLeave, setBlockLeave] = useState(true);
        const [redirectToCollection, setRedirectToCollection] = useState(false);
        const [product, setProduct] = useState<IProduct>(getProduct());
        const [aiState, setAiState] = useState<IAiProductState>({
            filledFieldsCount: 0,
            finished: false,
            loading: false,
        })

        const notSortedImages = filter(productImageStore.list, { productId: product?.id });
        const images = orderBy(notSortedImages, ['orderNumber'], ['asc']);

        const [missFieldsSpecUsed, setMissFieldsSpecUsed] = useState(false);

        useEffect(() => {
            if (!missFieldsSpecUsed) {
                var refs = [
                    tiresFormRef,
                    aluminiumWheelsFormRef,
                    carsFormRef,
                    motoFormRef
                ]

                let validate = false;
                refs.forEach(f => {
                    if (f && f.current) {
                        const isValid = ValidationHelper.isValid(f.current.getInputs());
                        if (isValid) {
                            validate = true;
                        }
                    }
                })

                if (validate) {
                    setMissFieldsSpecUsed(true);
                    getMissingFields(false, product);
                }
            }
        }, [product]);

        useEffect(() => {
            setIsFormValid(ValidationHelper.isValid(getInputs()))
        }, [product])

        useEffect(() => {
            window.scroll(0, 0);

            Promise.all([
                facebookCatalogsApiService.getCatalogs(),
                facebookApiService.getCategories(),
                googleApiService.getCategories(),
                productApiService.getRequiredFields(product)
            ])
                .then(([catalogsData, fbCategories, gCategories, requiredFieldsData]) => {
                    // Handle product data and catalogs data here             
                    setFbCategories(fbCategories);
                    setGoogleCategories(gCategories);
                    setRequiredFields(requiredFieldsData.others.get("requiredFields"));
                })
                .catch(error => {
                    // Handle errors here
                })
                .finally(() => {
                    setLoading(false);
                });

            return () => {
                productApiService.cancelRequests();
                facebookCatalogsApiService.cancelRequests();
                facebookApiService.cancelRequests();
            }
        }, [])

        useEffect(() => {
            if (!aiState.finished && !aiState.loading && product.name.length > 5 && product.description.length > 5) {
                setAiState({
                    ...aiState,
                    loading: true,
                })

                /*   productApiService.getMissingFields(product, true)
                       .then(data => {
                           var fields = data.others.get("fields") as IField;
   
                           const count = Object.entries(fields).length;
                           setProduct(currentProduct => {
                               var p = currentProduct;
                               Object.entries(fields).forEach(([key, value]) => {
                                   p = ProductHelper.onChangeAutoProcess(p, key, value);
                               });
                               return p;
                           });
   
                           setAiState({
                               ...aiState,
                               loading: false,
                               filledFieldsCount: count,
                               finished: true,
                           })
                       });*/
            }
        }, [product.priceCZK, product.priceEUR, product.vatDeduction])

        useEffect(() => {
            if (redirectToCollection) {
                navigate(`/nahravac/kolekce/${collection.id}/prehled`);
            }
        }, [redirectToCollection])

        const pushToCollection = () => {
            setBlockLeave(false);
            setRedirectToCollection(true);
        }

        const [uploadImageLoading, setUploadImageLoading] = useState(false);
        const [createLoading, setCreateLoading] = useState(false);

        const handleUploadImages = async (images: File[]) => {
            if (created) {
                uploadImagesProcess(images, product);
            }
            else {
                const isValid = await validateAsync();

                if (isValid) {
                    setCreateLoading(true);
                    productApiService.create(product, false)
                        .then(data => {
                            if (data.success) {
                                const p = data.records.products.items[0];
                                setProduct(p);

                                setCreated(true);
                                uploadImagesProcess(images, p);
                            }
                            else {
                                setFormErrors(data.formErrors);
                            }
                        })
                        .finally(() => {
                            setCreateLoading(false);
                        })
                }
                else {
                    setFormErrors([
                        { key: '', message: 'Formulář obsahuje chyby. Zkontrolujte formulář a opakujte akci.' }
                    ])
                }
            }
        }

        const uploadImagesProcess = (images: File[], p: IProduct) => {
            setUploadImageLoading(true);
            uploadImages(images, p)
                .then(() => {
                    setUploadImageLoading(false);
                })
        }

        const handleCreateOrEdit = async (enable: boolean) => {
            const isValid = await validateAsync();

            if (isValid) {
                if (!created) {
                    productApiService.create(product, enable)
                        .then(data => {
                            if (data.success) {
                                pushToCollection();
                            }
                            else {
                                setFormErrors(data.formErrors);
                            }
                        })
                } else {
                    productApiService.edit(product.id, product, enable)
                        .then((data) => {
                            if (!data.success) {
                                setFormErrors(data.formErrors);
                                toast.error("Změny nebyly uloženy");
                            }
                            else {
                                pushToCollection();
                            }
                        })
                }
            }
            else {
                setFormErrors([
                    { key: '', message: 'Formulář obsahuje chyby' }
                ])
            }
        }

        const validateAsync = async (): Promise<boolean> => {

            let isValid = true;

            isValid = ValidationHelper
                .validateInputs(ValidationHelper.safeGetInputs(marketingChannelsRef));

            if (isValid) {
                const val = await basicFormRef.current.isContentValidAsync();
                isValid = val;
            }

            var inputs: React.MutableRefObject<IInputField>[] = concat(
                ValidationHelper.safeGetInputs(basicFormRef),
                ValidationHelper.safeGetInputs(pricesFormRef),
                ValidationHelper.safeGetInputs(categoriesFormRef),
                ValidationHelper.safeGetInputs(tiresFormRef),
                ValidationHelper.safeGetInputs(aluminiumWheelsFormRef),
                ValidationHelper.safeGetInputs(carsFormRef),
                ValidationHelper.safeGetInputs(motoFormRef),
                ValidationHelper.safeGetInputs(serversFormRef),
                ValidationHelper.safeGetInputs(businessInfoFormRef),
            );

            if (isValid) {
                isValid = ValidationHelper.validateInputs(inputs);
            }
            return isValid;
        };

        const getInputs = () => {
            var inputs: React.MutableRefObject<IInputField>[] = concat(
                ValidationHelper.safeGetInputs(marketingChannelsRef),
                ValidationHelper.safeGetInputs(basicFormRef),
                ValidationHelper.safeGetInputs(pricesFormRef),
                ValidationHelper.safeGetInputs(categoriesFormRef),
                ValidationHelper.safeGetInputs(tiresFormRef),
                ValidationHelper.safeGetInputs(aluminiumWheelsFormRef),
                ValidationHelper.safeGetInputs(carsFormRef),
                ValidationHelper.safeGetInputs(motoFormRef),
                ValidationHelper.safeGetInputs(serversFormRef),
                ValidationHelper.safeGetInputs(businessInfoFormRef),
            );
            return inputs;
        }

        if (loading) {
            return <LoadingScreen />
        }
        if (!product) {
            return <NotFound />
        }

        const showTiresCard = product.vehicleType === VehicleType.SpareParts && product.subcategory === ProductSubcategory.Tires;

        const showMotoCard = ProductType.Car &&
            (product.vehicleType === VehicleType.Motorcycle || product.vehicleType === VehicleType.QuadBike);

        const showVehicleCard = product.type === ProductType.Car &&
            (product.vehicleType === VehicleType.Car ||
                product.vehicleType === VehicleType.Commercial || product.vehicleType === VehicleType.Truck);

        const showAluminiumWheelsCard = product.vehicleType === VehicleType.SpareParts &&
            (product.subcategory === ProductSubcategory.AluminumWheels || product.subcategory === ProductSubcategory.AluminumWheelsPlusTires);

        return <>
            <LeavePageWarningPrompt when={blockLeave} />

            <div className="page-title-box">
                <h4 className="page-title">
                    <i className={product.type === ProductType.Others ?
                        "fas fa-shopping-bag" : "fas fa-car"}></i> Přidat inzerát - KOLEKCE "{collection.name}"
                </h4>
            </div>

            {!product.id &&
                <Alert variant={"warning"} className="mt-2">
                    <b>TIP: Výchozí hodnoty můžete přednastavit v&nbsp;
                        <Link to={`/nahravac/kolekce/${collection.id}/upravit`}>nastavení kolekce</Link></b>.
                </Alert>}

            <SelectMarketingChannelsCard
                ref={marketingChannelsRef}
                collection={collection}
                product={product}
                onChange={onChangeMarketingChannels}
                user={user}
                requiredFields={requiredFields}
            />

            <BasicInfoFormCard
                ref={basicFormRef}
                collection={collection}
                product={product}
                onChange={onChange}
                onBatchUpdate={onBatchUpdate}
                user={user}
                subcategories={subcategories}
                settings={globalSettings}
                requiredFields={requiredFields}
            />

            <PricesFormCard
                ref={pricesFormRef}
                collection={collection}
                onChange={onChange}
                product={product}
                settings={globalSettings}
                user={user}
                requiredFields={requiredFields}
                aiState={aiState}
            />

            <CategoriesFormCard
                ref={categoriesFormRef}
                onChange={onChange}
                product={product}
                user={user}
                requiredFields={requiredFields}
                subcategories={subcategories}
            />

            {showTiresCard &&
                <TiresFormCard
                    ref={tiresFormRef}
                    onChange={onChange}
                    product={product}
                    requiredFields={requiredFields}
                />}

            {showAluminiumWheelsCard &&
                <AluminiumWheelsFormCard
                    ref={aluminiumWheelsFormRef}
                    onChange={onChange}
                    product={product}
                    requiredFields={requiredFields}
                />}

            {showVehicleCard &&
                <>
                    <CarsFormCard
                        ref={carsFormRef}
                        onChange={(key, val) => onChange(key, val, null, carsFormRef)}
                        product={product}
                        requiredFields={requiredFields}
                    />

                    <CarsEquipmentFormCard
                        onChange={(key, val) => onChange(key, val, null, carsFormRef)}
                        product={product}
                        requiredFields={requiredFields}
                    />
                </>
            }

            {showMotoCard &&
                <MotoFormCard
                    ref={motoFormRef}
                    onChange={onChange}
                    product={product}
                    requiredFields={requiredFields}
                />}

            <ServersFormCard
                ref={serversFormRef}
                onChange={onChange}
                product={product}
                user={user}
                requiredFields={requiredFields}
                fbCategories={fbCategories}
                googleCategories={googleCategories}
                subcategories={subcategories}
            />

            {user.doNotStoreBusinessData === false &&
                <BusinessInfoFormCard
                    ref={businessInfoFormRef}
                    onChange={onChange}
                    product={product}
                    user={user}
                    requiredFields={requiredFields}
                />}

            <div className="card">
                <div className="card-header">
                    <SectionTitle title="OBRÁZKY" className="my-0" />
                </div>
                <div className="card-body">
                    <div className="row justify-content-center">
                        <div className="w-75">
                            {!isFormValid && !created && <p className="text-danger">Některá pole nejsou vyplněna nebo vykazují chyby. Následně budete moci vybrat obrázky.</p>}

                            <div onClick={() => {
                                if (!isFormValid && !created) {
                                    handleUploadImages([]);
                                }
                            }}>
                                <Dropzone onDrop={(files) => handleUploadImages(files)} disabled={!isFormValid && !created}>
                                    {({ getRootProps, getInputProps, }) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} accept="image/x-png,image/jpeg" />
                                                <div className={`dropzone-area ${!isFormValid && !created && 'dropzone-area-disabled'} my-4`}>
                                                    {created ? <h3>Vybrat obrázky</h3> : <h3>Uložit inzerát a vybrat obrázky</h3>}
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>

                            {createLoading &&
                                <WaitScreen
                                    title="Vytvářím inzerát"
                                />}

                            {uploadImageLoading &&
                                <WaitScreen
                                    title="Nahrávám obrázky..."
                                />}

                            <ImagesSortableList
                                images={images}
                                product={product}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <FormErrorSummary
                errors={formErrors}
            />

            <div className="mg-t-30 text-center">
                <div className="mt-4">
                    <div className="wizardStepNavigation form-layout-footer">
                        <button
                            className="btn btn-success px-sm-4 btn-only-xs-block"
                            onClick={() => handleCreateOrEdit(true)}
                        >
                            <i className="fa fa-upload mr-2"></i>
                            Zveřejnit inzerát</button>

                        <button
                            onClick={() => handleCreateOrEdit(false)}
                            className="btn btn-primary px-sm-4 btn-only-xs-block"
                        >
                            <i className="fas fa-save mr-2"></i>
                            Uložit inzerát
                        </button>
                    </div>
                </div>
            </div >
        </>
    }))