import { AxiosError, AxiosResponse } from "axios";
import IResponseData from "../Interfaces/IResponseData";
import IUser from "../Interfaces/IUser";
import BaseApiService from "./BaseApiService";
import { IAppSettingsForm } from "../Interfaces/Requests/Settings/IAppSettingsForm";
import { IBilling } from "../Interfaces/IBilling";

export default class UserApiService extends BaseApiService {

    public getCurrentUser(): Promise<IUser> {
        return new Promise((resolve, reject): any => {
            this.getRequest('user', {})
                .then(response => {
                    if (response.data) {
                        const user: IUser = response.data.records.currentUser;
                        return resolve(user);
                    }

                }).catch(reject)
                .catch((error: AxiosError) => {

                    if (error.response.status === 401) {
                        return resolve(null);
                    }
                });
        })
    }

    public getDashboard(): Promise<IResponseData> {
        return this.getRequest(`user/dashboard`, {})
            .then(response => {
                return response.data;
            })
    }

    public enableExport(): Promise<IResponseData> {
        return this.postRequest(`user/export/enable`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public disableExport(): Promise<IResponseData> {
        return this.postRequest(`user/export/disable`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public enableWebEmbedExport(): Promise<IResponseData> {
        return this.postRequest(`user/web-embed/enable`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public disableWebEmbedExport(): Promise<IResponseData> {
        return this.postRequest(`user/web-embed/disable`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public saveAppSettings(form: IAppSettingsForm): Promise<IResponseData> {
        return this.postRequest('user/settings/app', { ...form }, {})
            .then(response => {
                return response.data;
            })
    }

    public saveBilling(billing: IBilling): Promise<IResponseData> {
        return this.putRequest('user/billing', { ...billing }, {})
            .then(response => {
                return response.data;
            })
    }

    public getBillingByIc(ic: string): Promise<IResponseData> {
        return this.postRequest('user/billing/get-by-ic', { ic }, {})
            .then(response => {
                return response.data;
            })
    }

    public getSales(): Promise<IResponseData> {
        return this.getRequest('user/sales', {})
            .then(response => {
                return response.data;
            })
    }

    public orderIP(): Promise<IResponseData> {
        return this.postRequest('user/order-ip', {}, {})
            .then(response => {
                return response.data;
            })
    }

    public getPriceForNewIp(): Promise<IResponseData> {
        return this.getRequest('user/order-ip/price', {})
            .then(response => {
                return response.data;
            })
    }

    public ablyAuth(): Promise<AxiosResponse<IResponseData, any>> {
        return this.postRequest('/user/ably/auth', {}, {})
            .then(response => {
                return response;
            })

    }

    async getDeletedProductsExcelUrl() : Promise<AxiosResponse<any, any>> {
        return await this.getRequest('/user/deleted-products-excel', {});
    }
}